<template>
	<section id="feedback" class="py-10">
		<v-container fluid>
			<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">
				{{ $t('landing.feedbackTitle') }}
			</p>
			<p class="text-h6 text-center mx-auto" style="max-width: 800px">
				{{ $t('landing.feedbackDescription') }}
			</p>
			<v-card max-width="1200" class="mt-15 mx-auto d-flex flex-wrap justify-space-around" flat tile color="transparent">
				<v-slide-group show-arrows>
					<v-slide-item class="mx-5 my-2">
						<v-card rounded="xl" :max-width="$vuetify.breakpoint.mdAndUp ? 450 : 200" class="mx-5">
							<div class="d-flex flex-no-wrap justify-space-between">
								<div>
									<v-card-title class="headline"> Maria Lagos </v-card-title>
									<v-card-subtitle>
										Cada any s'han fet modificacions a la manera d'interactuar amb el SOS i es nota cada millora que es fa any rere any. ¡Ara ho tinc
										tot a un clic!
									</v-card-subtitle>
									<v-card-actions>
										<v-rating :value="5" readonly background-color="white" color="yellow accent-4" dense half-increments hover size="18" />
									</v-card-actions>
								</div>
								<v-avatar v-if="$vuetify.breakpoint.mdAndUp" class="ma-3" size="125">
									<v-img src="@/assets/images/landing//testi-2.jpeg" />
								</v-avatar>
							</div>
						</v-card>
					</v-slide-item>
					<v-slide-item class="mx-5 my-2">
						<v-card rounded="xl" :max-width="$vuetify.breakpoint.mdAndUp ? 450 : 200" class="mx-5">
							<div class="d-flex flex-no-wrap justify-space-between">
								<div>
									<v-card-title class="headline"> Jesús Higueras </v-card-title>
									<v-card-subtitle>
										El hecho de que se puedan compartir las carpetas con Google Drive hace que sea mucho más fácil estudiar, porque las tengo
										sincronizadas en mi ordenador.
									</v-card-subtitle>
									<v-card-actions>
										<v-rating :value="4.5" readonly background-color="white" color="yellow accent-4" dense half-increments hover size="18" />
									</v-card-actions>
								</div>
								<v-avatar v-if="$vuetify.breakpoint.mdAndUp" class="ma-3" size="125">
									<v-img src="@/assets/images/landing//testi-3.jpeg" />
								</v-avatar>
							</div>
						</v-card>
					</v-slide-item>
					<v-slide-item class="mx-5 my-2">
						<v-card rounded="xl" :max-width="$vuetify.breakpoint.mdAndUp ? 450 : 200" class="mx-5">
							<div class="d-flex flex-no-wrap justify-space-between">
								<div>
									<v-card-title class="headline"> Víctor Sainz </v-card-title>
									<v-card-subtitle>
										Creo que esta plataforma debería existir en todas las carreras. Ayuda más que el propio campus de la universidad. ¡Además ahora
										ayudarán a buscar prácticas y trabajo!
									</v-card-subtitle>
									<v-card-actions>
										<v-rating :value="4.5" readonly background-color="white" color="yellow accent-4" dense half-increments hover size="18" />
									</v-card-actions>
								</div>
								<v-avatar v-if="$vuetify.breakpoint.mdAndUp" class="ma-3" size="125">
									<v-img src="@/assets/images/landing//testi-5.jpg" />
								</v-avatar>
							</div>
						</v-card>
					</v-slide-item>
				</v-slide-group>
			</v-card>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingFeedback',
}
</script>
